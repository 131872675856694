/*eslint-disable import/no-anonymous-default-export, no-template-curly-in-string */
export default {
  REACT_APP_API_BASE_URL: 'https://installer-api.fibercrew.io/api'.includes('REACT_APP_API_BASE_URL')
    ? process.env.REACT_APP_API_BASE_URL
    : 'https://installer-api.fibercrew.io/api',

  REACT_APP_GRAPHQL_API_BASE_URL: 'https://installer-api.fibercrew.io/api/graphql'.includes('REACT_APP_GRAPHQL_API_BASE_URL')
    ? process.env.REACT_APP_GRAPHQL_API_BASE_URL
    : 'https://installer-api.fibercrew.io/api/graphql',
};
